// extracted by mini-css-extract-plugin
export var blocksInRowsWithOneActive = "payload-module--blocks-in-rows-with-one-active--73e3e";
export var blocksInRowsWithOneActiveGrey = "payload-module--blocks-in-rows-with-one-active-grey--e7ec7";
export var blocksInRowsWithOneActiveGreyInnerWrapper = "payload-module--blocks-in-rows-with-one-active-grey-inner-wrapper--20a17";
export var blocksInRowsWithOneActiveGreySubtitle = "payload-module--blocks-in-rows-with-one-active-grey-subtitle--596c7";
export var blocksInRowsWithOneActiveGreyTitlesContainer = "payload-module--blocks-in-rows-with-one-active-grey-titles-container--34161";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--88842";
export var blocksInRowsWithOneActiveSubtitle = "payload-module--blocks-in-rows-with-one-active-subtitle--41eac";
export var blocksInRowsWithOneActiveTitlesContainer = "payload-module--blocks-in-rows-with-one-active-titles-container--619b7";
export var blocksRedirectingToResources = "payload-module--blocks-redirecting-to-resources--c494d";
export var blocksRedirectingToResourcesSubtitle = "payload-module--blocks-redirecting-to-resources-subtitle--5a3db";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--6c238";
export var borderWidth = "payload-module--border-width--473b9";
export var coloredBlocksWithRedirectsColumn = "payload-module--colored-blocks-with-redirects-column--dce2b";
export var coloredBlocksWithRedirectsContainer = "payload-module--colored-blocks-with-redirects-container--dd4da";
export var coloredBlocksWithRedirectsInnerWrapper = "payload-module--colored-blocks-with-redirects-inner-wrapper--befac";
export var coloredBlocksWithRedirectsRightPart = "payload-module--colored-blocks-with-redirects-right-part--9b47e";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--67c22";
export var contactButtonImageCustomSection = "payload-module--contact-button-image-custom-section--95475";
export var contactButtonImageSectionButton = "payload-module--contact-button-image-section-button--507c7";
export var contactButtonImageSectionContent = "payload-module--contact-button-image-section-content--96c09";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--38425";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--cfa97";
export var header = "payload-module--header--adeed";
export var headerBorder = "payload-module--header-border--0436e";
export var headerTitleAndBorderContainer = "payload-module--header-title-and-border-container--d11a7";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--f4389";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--f94b1";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--4141a";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--d52e9";
export var sectionUnderHeaderText = "payload-module--section-under-header-text--b80df";
export var techStackCategoryTitle = "payload-module--tech-stack-category-title--c727d";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--d437e";
export var techStackRectangleStyles = "payload-module--tech-stack-rectangle-styles--2f6e1";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--491ce";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--16124";
export var techStackSubtitle = "payload-module--tech-stack-subtitle--b7082";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--55a76";
export var testimonialTitle = "payload-module--testimonial-title--d97e5";
export var testimonialsInnerWrapper = "payload-module--testimonials-inner-wrapper--20f77";