import React from 'react';
import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import {
  MobileHeaderLayoutB,
  DesktopHeaderLayoutB,
  SectionUnderHeaderWithUnderline,
  BlocksInRowsWithOneActive,
  ContactButtonImageSection,
  OpacityScrolledSlider,
  TechStackWithCategories,
  BlocksRedirectingToResources,
  NewClientSingleTestimonial,
  ColoredBlocksWithRedirects,
  BannerWithImage,
} from '@commons/index';
import { CompanyInNumbers } from '@pages-impl/home';

import {
  SEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksInRowsWithOneActiveGreyProps,
  contactButtonImageSectionProps,
  blocksInRowsWithOneActiveProps,
  opacityScrolledSliderProps,
  techStackProps,
  blocksRedirectingToResourcesProps,
  testimonialProps,
  companyInNumbersProps,
  coloredBlocksWithRedirectsProps,
  bannerWithImageProps,
} from '@pages-impl/data-science/payload';

export default function DataScienceServices(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Use your data to operate efficiently"
      {...props}
    >
      <SEO {...SEOProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <MobileHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveGreyProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <BannerWithImage {...bannerWithImageProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <TechStackWithCategories {...techStackProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <CompanyInNumbers {...companyInNumbersProps} />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
