import React from 'react';

import { paths } from '@src/constants/pathsEnum';
import featuredImage from './images/featured-image.jpg';
import makeYourDataWorkImage from './images/make-your-data-work.png';
import neptuneLogo from '@images/logos-partners/Neptune-logo.svg';

import {
  jupyter,
  python,
  scikitLearn,
  xGBoost,
  statsmodels,
  darts,
  prophet,
  pandas,
  sktime,
  nixtla,
  sciPy,
  gluonTS,
  gPflow,
  hyperopt,
  pyTorch,
  keras,
  tensorFlow,
  apacheMXNet,
  plotly,
  matplotlib,
  seaborn,
  grafana,
  influxDB,
  openTSDB,
  postgreSQL,
} from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Data Science Services & AI/ML Solutions - CodiLime Company',
  description:
    'Artificial intelligence and machine learning are the way to accelerate your technology development. See how we can help you use these techniques for your benefit.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Data science <br />
      services
    </>
  ),
  titleBorderWidth: styles.borderWidth,
  classNames: {
    header: styles.header,
    border: styles.headerBorder,
    titleAndBorderContainer: styles.headerTitleAndBorderContainer,
  },
};

export const sectionUnderHeaderProps = {
  contactButtonLabel: 'Empower your business',
  textPartOne:
    'The competitive technology market requires a faster, more efficient, and foolproof approach to software product development. An answer to these needs is using artificial intelligence and machine learning models. These data science techniques make your data work for you, helping you operate more efficiently.',
  classNames: {
    text: styles.sectionUnderHeaderText,
    button: styles.sectionUnderHeaderButton,
    contentWrapper: styles.sectionUnderHeaderContentWrapper,
  },
};

export const blocksInRowsWithOneActiveGreyProps = {
  title: 'Data science services for smarter business',
  subtitle: (
    <>
      Data science means extracting valuable business insights from vast amounts of your data by using AI, machine
      learning, deep learning and other specific algorithms and techniques. At CodiLime, we offer various services that
      will help you reach your business objectives and increase your market competitiveness.
    </>
  ),
  blocks: [
    {
      title: 'AI/ML introduction assessment',
      description: (
        <>
          We will look at your existing project or product and assess whether it has the{' '}
          <b>potential to introduce AI/ML techniques</b>. If so, we will indicate exactly these places and show you the
          benefits that <b>AI/ML-enhanced business logic</b> can bring you.
        </>
      ),
    },
    {
      title: 'Machine learning models and implementation',
      description: (
        <>
          Thanks to our experience in working with <b>data</b> and <b>building ML models</b>, we will design and create
          a solution suitable for your case. We will also help you <b>integrate</b> it with the existing code of your
          solution if necessary.
        </>
      ),
    },
    {
      title: 'AI/ML solution review & optimization',
      description: (
        <>
          If you feel that <b>your existing AI/ML solution does not meet your expectations</b> for some reason, we can
          analyze it for you and give specific <b>recommendations</b> on how to improve it. We can also optimize it or
          modify it for you.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveGrey,
    innerWrapper: styles.blocksInRowsWithOneActiveGreyInnerWrapper,
    subtitleStyles: styles.blocksInRowsWithOneActiveGreySubtitle,
    titlesContainer: styles.blocksInRowsWithOneActiveGreyTitlesContainer,
    blocksContainer: styles.blocksInRowsWithOneActiveGreyBlocksContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageCustomSection,
      customContainer: styles.contactButtonImageCustomContainer,
    },
  },
  title: 'Make your data work for you',
  imageAlt: 'Make your data work for you',
  isH2: true,
  buttonTitle: 'Contact us',
  image: makeYourDataWorkImage,
  classNames: {
    customContent: styles.contactButtonImageSectionContent,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionCustomContainer,
    customButton: styles.contactButtonImageSectionButton,
  },
};

export const blocksInRowsWithOneActiveProps = {
  id: 'expertise',
  title: 'Our data science expertise in practice',
  subtitle: (
    <>
      Our data science approach is problem-based. We build models and algorithms that solve specific challenges and give
      you the answers you need.
    </>
  ),
  blocks: [
    {
      title: 'Data exploration',
      description: (
        <>
          Exploration analysis is essential to properly <b>understand the characteristics of underlying data</b>. We use
          it to propose the most promising ML models (e.g. for a given class of problem) instead of immediately starting
          with effortful experiments with various available models.
        </>
      ),
    },
    {
      title: 'Time series forecasting',
      description: (
        <>
          Time series forecasting means using historical, time-stamped data to enable <b>accurate predictions</b>. Our
          services include experimentation with various predictive models, which leads to the optimal one in terms of
          accuracy and execution time.
        </>
      ),
    },
    {
      title: 'Capacity planning',
      description: (
        <>
          By constantly monitoring the consumption of network resources (e.g. link occupancy) and by using ML techniques
          to forecast this consumption in the future, any necessary{' '}
          <b>network capacity upgrade can be made at the right time</b>. A similar approach can be used in a data center
          to proactively increase CPU, memory and storage resources.
        </>
      ),
    },
    {
      title: 'Intelligent alerting',
      description: (
        <>
          Alerts are usually triggered (within a monitoring application) when some event occurs, some condition is met,
          or the measured value of a certain metric exceeds a defined threshold. However, triggering an alarm does not
          necessarily lead directly to remedial actions. Using{' '}
          <b>AI/ML techniques can filter out non-meaningful alarms</b> and make alerting more intelligent.
        </>
      ),
    },
    {
      title: 'Proactive load balancing',
      description: (
        <>
          Load balancing in a networking environment and lack of congested elements is the desired state for proper
          operation. Continuous observation of traffic flows’ bit rate and forecasting such time series in terms of
          trends and seasonalities using ML models allows for <b>proactive and automatic</b> routing reconfigurations.
          This way, you can achieve the desired load balancing on the links.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActive,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    titlesContainer: styles.blocksInRowsWithOneActiveTitlesContainer,
    blocksContainer: styles.blocksInRowsWithOneActiveBlocksContainer,
  },
};

export const opacityScrolledSliderProps = {
  title: 'Data science benefits for your business',
  textBlocks: [
    <>
      In-depth data analysis for <span>better business decisions</span>
    </>,
    <>
      <span>Effective risk management</span> thanks to predictive analytics
    </>,
    <>
      <span>Enhanced operational performance</span> via automation
    </>,
    <>
      <span>Actionable insights</span> that your competitors don’t have access to
    </>,
    <>
      Improved <span>customer experience</span>
    </>,
  ],
  blocksGap: 37,
  topOpacity: 0.44,
  shiftDown: 0.3,
  classNames: {
    innerWrapper: styles.opacitySliderInnerWrapper,
    titlesContainer: styles.opacitySliderTitlesContainer,
  },
};

export const techStackProps = {
  sectionProps: {
    title: 'Data science technologies',
    subtitle:
      'The tools we use for data science allow us to deliver excellent results according to the needs of your data science project. Our stack is constantly expanding with new data science technologies.',
    classNames: {
      innerWrapper: styles.techStackInnerWrapper,
      titlesContainer: styles.techStackTitlesContainer,
      subtitleStyles: styles.techStackSubtitle,
    },
  },
  classNames: {
    singleColumn: styles.techStackSingleColumn,
    singleCategory: styles.techStackSingleCategory,
    categoryTitle: styles.techStackCategoryTitle,
    rectangleStyles: styles.techStackRectangleStyles,
  },
  blocksForFirstColumn: [
    {
      categoryTitle: 'Experimentation environment',
      technologiesData: [jupyter, python],
    },
    {
      categoryTitle: 'Data analysis and machine learning models',
      technologiesData: [
        scikitLearn,
        xGBoost,
        statsmodels,
        darts,
        prophet,
        pandas,
        sktime,
        nixtla,
        sciPy,
        gluonTS,
        gPflow,
        hyperopt,
      ],
    },
    {
      categoryTitle: 'Deep Learning',
      technologiesData: [pyTorch, keras, tensorFlow, apacheMXNet],
    },
    {
      categoryTitle: 'Data Visualisation',
      technologiesData: [plotly, matplotlib, seaborn, grafana],
    },
    {
      categoryTitle: 'Databases',
      technologiesData: [influxDB, openTSDB, postgreSQL],
    },
  ],
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: <>Our publications about data science</>,
    subtitle: (
      <>
        Data science is an ever-evolving subject, and there’s much to say about it. Head to our blog to find articles by
        business professionals, data engineers, data scientists and data analytics experts.
      </>
    ),
    classNames: {
      titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer,
      subtitleStyles: styles.blocksRedirectingToResourcesSubtitle,
    },
  },
  blocks: [
    {
      text: 'Data Science vs. Data Analytics - main differences overview',
      link: '/blog/data-science-vs-data-analytics/',
    },
    {
      text: 'Data Science vs. Machine Learning: understanding the difference',
      link: '/blog/data-science-vs-machine-learning/',
    },
    {
      text: 'AI and machine learning landscape for networks',
      link: '/blog/ai-machine-learning-for-networks/',
    },
    {
      text: 'Types of Artificial Intelligence - a general overview of a formidable technology',
      link: '/blog/types-of-artificial-intelligence/',
    },
  ],
};

export const testimonialProps = {
  testimonial: {
    quote:
      'CodiLime’s expertise in software engineering was indispensable at the beginning of Neptune’s journey. CodiLime helped us build and release the first version of Neptune and convince investors that our product is a real game changer for data scientists. Without any hesitation, I can recommend CodiLime as a reliable technology partner for every tech startup.',
    author: 'Piotr Niedźwiedź - CEO',
    logo: neptuneLogo,
    logoAlt: 'Neptune.ai',
  },
  classNames: {
    innerWrapper: styles.testimonialsInnerWrapper,
  },
  paragraphTitleStyles: styles.testimonialTitle,
};

export const companyInNumbersProps = {
  id: 'about-codilime',
  title: 'Meet the CodiLime company',
  imgSrc: '',
  classNames: {
    titlesContainer: styles.companyInNumbersTitlesContainer,
  },
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Explore our other services',
  subtitle:
    'Data science is only a small part of what CodiLime can do for you. We provide support in many fields, including data, design, networks, and software engineering.',
  blocks: [
    {
      text: (
        <>
          <span>Network professional</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
    {
      text: (
        <>
          <span>R&D services</span>
        </>
      ),
      link: paths.SERVICES.R_D,
    },
    {
      text: (
        <>
          <span>Data engineering</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.DATA_ENGINEERING,
    },
    {
      text: (
        <>
          <span>Network solution</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_SOLUTION_SERVICES,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksWithRedirectsInnerWrapper,
    container: styles.coloredBlocksWithRedirectsContainer,
    rightPart: styles.coloredBlocksWithRedirectsRightPart,
    column: styles.coloredBlocksWithRedirectsColumn,
  },
};

export const bannerWithImageProps = {
  title: 'Discover AI/ML solutions that will boost your network performance',
};
